body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#ProjectSummary .keyboard{
  padding-left: 2px;
}

#ProjectSummary .content{
  padding-left: 12px;
}

#KnowledgeSummary .lightbulb{
  padding-left: 8.571px;
}

#KnowledgeSummary .content{
  padding-left: 17px;
}

.mt-20{
  margin-top: 20px;
}

.mb-40{
  margin-bottom: 40px;
}

.mt-40{
  margin-top: 40px;
}